import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import ClearLocalStorageAndCache from '../../utils/storage';
import { useTheme } from '@mui/material/styles';

const headerStyle = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[4],
});

const logoStyle = (theme) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
});

const buttonStyle = (theme) => ({
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const Header = () => {
  const theme = useTheme();

  const handleLogout = () => {
    // Implement logout functionality
    ClearLocalStorageAndCache();
  };

  return (
    <Box sx={headerStyle(theme)}>
      <Typography sx={logoStyle(theme)}>DocsTribe</Typography>
      <Button 
        variant="outlined" 
        onClick={handleLogout} 
        sx={buttonStyle(theme)}
      >
        LOGOUT
      </Button>
    </Box>
  );
};

export default Header;