import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FeaturesContext } from '../../context/FeatureContext';
import * as Routes from '../../constants/RouteConstants';
import { useTheme, Box, Typography, Icon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { LocalHotel as ICUHospitalIcon, LocalHospital as LocalHospitalIcon } from '@mui/icons-material';



const MenuTabItem = ({ icon, label, isActive, onClick }) => {
  const theme = useTheme();

  const menuItemStyle = {
    padding: theme.spacing(1),
    cursor: 'pointer',
    color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
    borderLeft: isActive ? `2px solid ${theme.palette.primary.main}` : 'none',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s, color 0.3s',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
  };

  return (
    <Box sx={menuItemStyle} onClick={onClick}>
      {icon}
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
};

const menuTabContainerStyle = (theme) => ({
  width: '60px',
  flexShrink: 0,
  overflowY: 'auto',
  height: 'calc(100vh - 64px - 48px)', // Adjust based on header and footer height
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const MenuTabs = () => {
  const { features } = useContext(FeaturesContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('Home');
  const theme = useTheme();

  useEffect(() => {
    const determineActiveTab = (pathname) => {
      switch (pathname) {
        case Routes.HOME_ROUTE:
          return 'Home';
        case Routes.RISK_ANALYSIS_ROUTE:
          return 'PDCM';
        case Routes.OPD_ANALYSIS_ROUTE:
          return 'OpdAnalysis';
        case Routes.CAMP_ANALYSIS_ROUTE:
          return 'CampAnalysis';
        case Routes.SEARCH_PATIENTS:
          return 'SearchPatients';
        case Routes.SALES_DASHBOARD_ROUTE:
          return 'SalesDashboard';
        case Routes.FILE_UPLOAD_ROUTE:
          return 'FileUpload';
        case Routes.LIST_SALES_USER_ROUTE:
          return 'ListSalesUser';
        case Routes.ANALYTICS_ROUTE:
          return 'Analytics';
        case Routes.SALES_AGENT_ANALYTICS_ROUTE:
          return 'SalesAgentAnalytics';
        case Routes.ADMIN_IP_PATIENTS_LIST:
          return 'PDCM';
        case Routes.ADMIN_OP_PATIENTS_LIST:
          return 'OPD';
        default:
          return 'Home';
      }
    };

    setActiveTab(determineActiveTab(location.pathname));
  }, [location]);

  const handleTabClick = (tabName, route) => {
    setActiveTab(tabName);
    navigate(route);
  };

  return (
    <Box sx={menuTabContainerStyle(theme)}>
      <MenuTabItem
        icon={<HomeIcon />}
        label="Home"
        isActive={activeTab === 'Home'}
        onClick={() => handleTabClick('Home', Routes.HOME_ROUTE)}
      />
      {features.risk_analysis && (
        <MenuTabItem
          icon={<ICUHospitalIcon />}
          label="PDCM"
          isActive={activeTab === 'PDCM'}
          onClick={() => handleTabClick('PDCM', Routes.RISK_ANALYSIS_ROUTE)}
        />
      )}
      {features.opd_analysis && (
        <MenuTabItem
          icon={<LocalHospitalIcon />}
          label="OPD"
          isActive={activeTab === 'OpdAnalysis'}
          onClick={() => handleTabClick('OpdAnalysis', Routes.OPD_ANALYSIS_ROUTE)}
        />
      )}
      {features.camps_analysis && (
        <MenuTabItem
          icon={<LocalHospitalIcon />}
          label="OPD"
          isActive={activeTab === 'OpdAnalysis'}
          onClick={() => handleTabClick('OpdAnalysis', Routes.OPD_ANALYSIS_ROUTE)}
        />
      )}
      {features.ip_patients && (
        <MenuTabItem
          icon={<ICUHospitalIcon />}
          label="PDCM"
          isActive={activeTab === 'PDCM'}
          onClick={() => handleTabClick('PDCM', Routes.ADMIN_IP_PATIENTS_LIST)}
        />
      )}
      {features.op_patients && (
        <MenuTabItem
          icon={<LocalHospitalIcon />}
          label="OPD"
          isActive={activeTab === 'OpdAnalysis'}
          onClick={() => handleTabClick('OpdAnalysis', Routes.ADMIN_OP_PATIENTS_LIST)}
        />
      )}
      {features.camp_analysis && (
        <MenuTabItem
          icon={<AnalyticsIcon />}
          label="Camp"
          isActive={activeTab === 'CampAnalysis'}
          onClick={() => handleTabClick('CampAnalysis', Routes.CAMP_ANALYSIS_ROUTE)}
        />
      )}
      {features.search_patients && (
        <MenuTabItem
          icon={<SearchIcon />}
          label="Search"
          isActive={activeTab === 'SearchPatients'}
          onClick={() => handleTabClick('SearchPatients', Routes.SEARCH_PATIENTS)}
        />
      )}
      {features.sales_dashboard && (
        <MenuTabItem
          icon={<DashboardIcon />}
          label="Sales"
          isActive={activeTab === 'SalesDashboard'}
          onClick={() => handleTabClick('SalesDashboard', Routes.SALES_DASHBOARD_ROUTE)}
        />
      )}
      {features.file_upload && (
        <MenuTabItem
          icon={<CloudUploadIcon />}
          label="Upload"
          isActive={activeTab === 'FileUpload'}
          onClick={() => handleTabClick('FileUpload', Routes.FILE_UPLOAD_ROUTE)}
        />
      )}
      {features.list_sales_user && (
        <MenuTabItem
          icon={<SupervisorAccountIcon />}
          label="Users"
          isActive={activeTab === 'ListSalesUser'}
          onClick={() => handleTabClick('ListSalesUser', Routes.LIST_SALES_USER_ROUTE)}
        />
      )}
      {features.analytics && (
        <MenuTabItem
          icon={<InsertChartIcon />}
          label="Analytics"
          isActive={activeTab === 'Analytics'}
          onClick={() => handleTabClick('Analytics', Routes.ANALYTICS_ROUTE)}
        />
      )}
      {features.sales_agent_analytics && (
        <MenuTabItem
          icon={<PersonIcon />}
          label="Agent"
          isActive={activeTab === 'SalesAgentAnalytics'}
          onClick={() => handleTabClick('SalesAgentAnalytics', Routes.SALES_AGENT_ANALYTICS_ROUTE)}
        />
      )}
    </Box>
  );
};

export default MenuTabs;